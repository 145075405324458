import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import GrandPrixDriver from "../../../components/GrandPrix/GrandPrixDriver";
import GrandPrixTeam from "../../../components/GrandPrix/GrandPrixTeam";
import GrandPrixPosition from "../../../components/GrandPrix/GrandPrixPosition";
import GrandPrixTyre from "../../../components/GrandPrix/GrandPrixTyre";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faAngleUp,
  faAngleDown,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import GrandPrixLaps from "../GrandPrixLaps";
import { Lap, DriverSession } from "./Race.types";

const RaceRow = ({
  driver,
  testLap,
  testDriver,
  activeDriver,
  setActiveDriver,
  session,
  index,
  typeRaces,
  hasTeams,
  laps,
  classFilter,
}: {
  driver: DriverSession[];
  testLap: Lap;
  testDriver: DriverSession;
  activeDriver: { [key: string]: number };
  setActiveDriver: any;
  session: string;
  index: number;
  typeRaces?: number;
  hasTeams?: number;
  laps?: { laps: number; valid_laps: number | null };
  classFilter: string | null;
}) => {
  useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const showDriverLaps = (th: any) => {
    setActiveDriver({ ...activeDriver, [session]: !showDetails ? index : -1 });
    setShowDetails(!showDetails);
  };

  useEffect(() => {
    setShowDetails(false);
    setActiveDriver({});
  }, [testLap]);

  useEffect(() => {
    if (activeDriver[session] !== index) setShowDetails(false);
  }, [activeDriver[session]]);

  const getBalance = (balance: number) => {
    if (balance < 0)
      return (
        <>
          <FontAwesomeIcon icon={faAngleDown} />
          <span>{Math.abs(balance)}</span>
        </>
      );
    else if (balance === 0)
      return (
        <>
          <FontAwesomeIcon icon={faAngleRight} />
          <span>0</span>
        </>
      );
    else
      return (
        <>
          <FontAwesomeIcon icon={faAngleUp} />
          <span>{balance}</span>
        </>
      );
  };
  const getBalanceClass = (balance: number) => {
    if (balance > 0) return "balance-up";
    else if (balance < 0) return "balance-dw";
    else return "balance-eq";
  };

  const setHeatsJSX = (driver: DriverSession) => {
    const cols = [];
    if (laps != null) {
      for (let i = 0; i < laps.laps; i++) {
        const lap_ = driver.laps.find((lap_) => lap_.num === i + 1);
        cols.push(
          <div className="gp-heats text-center">
            <label className={lap_?.valid_heat ? "" : "low-opacity"}>
              {lap_ == null ? "" : lap_.time}
            </label>
          </div>
        );
      }
    }
    return <>{cols}</>;
  };

  const getLaps = (driver: DriverSession) => {
    if (typeRaces === 1) {
      return driver.laps.filter((lap) => lap.time !== "").length;
    }
    return driver.laps == null ? 0 : driver.laps.length;
  };
  return (
    <div
      className={`gp-driver-laps-container ${
        activeDriver.hasOwnProperty(session) &&
        activeDriver[session] !== -1 &&
        activeDriver[session] !== index &&
        !showDetails &&
        "low-opacity"
      }`}
    >
      <div
        className={`gp-driver-row flex ${
          driver[0].team === "LigaVirtualF1 Team" ? "lvf1-driver" : ""
        }`}
      >
        {driver[0].balance != null && (
          <div
            className={`gp-balance flex-center text-center ${getBalanceClass(
              driver[0].balance
            )}`}
          >
            {getBalance(driver[0].balance)}
          </div>
        )}
        <GrandPrixPosition driver={driver[0]} session={session} />
        <GrandPrixDriver driver={driver} />
        {hasTeams === 1 && <GrandPrixTeam driver={driver[0]} />}
        {driver[0].class !== null && driver[0].class_color != null && (
          <div className="gp-sector flex-center text-center">
            <label
              className="class_content"
              style={{ background: driver[0].class_color }}
            >
              {driver[0].class.toUpperCase()}
            </label>
          </div>
        )}
        <div className="gp-gap flex-center text-center">
          <label>
            {driver[0].gapGral != null && classFilter == null
              ? driver[0].gapGral
              : driver[0].gap}
          </label>
        </div>
        {testLap != null && (
          <>
            <div className="gp-hotlap flex-center text-center">
              <label>{driver[0].race_time}</label>
            </div>
            {typeRaces === 1 &&
              testDriver.laps.length > 1 &&
              setHeatsJSX(driver[0])}
            <div className="gp-laps flex-center text-center">
              <label>{getLaps(driver[0])}</label>
            </div>
            {typeRaces !== 1 && (
              <>
                <div className="gp-pitstops flex-center text-center">
                  <label>
                    {driver[0].pit_stops != null
                      ? driver[0].pit_stops.length
                      : 0}
                  </label>
                </div>
                <div className="gp-laps-pitstops flex-center text-center">
                  <label
                    title={driver[0].pit_stops
                      .map(function (elem) {
                        return "L" + elem.lap;
                      })
                      .join(", ")}
                  >
                    {driver[0].pit_stops != null &&
                      driver[0].pit_stops.map((pitstop) => {
                        return (
                          <span
                            key={pitstop.lap}
                            className={pitstop.isPenalty ? "penalty" : ""}
                          >
                            L{pitstop.lap}
                          </span>
                        );
                      })}
                  </label>
                </div>
                {testLap.hasOwnProperty("compounds") &&
                  testLap.compounds.front !== null && (
                    <div className="gp-tyres flex-center text-center">
                      {driver[0].tyreBrand != null && (
                        <img
                          title={driver[0].tyreBrand}
                          alt={driver[0].tyreBrand}
                          className="brand-image"
                          src={`/assets/Tyres/Brands/${driver[0].tyreBrand}.png`}
                        />
                      )}
                      {driver[0].tyres.map((tyres, index) => {
                        return <GrandPrixTyre key={index} tyre={tyres} />;
                      })}
                    </div>
                  )}
              </>
            )}
          </>
        )}
        {testDriver.points != null && testDriver.points > 0 && (
          <div className="gp-points flex-center text-center">
            <label>{driver[0].points === 0 ? "" : driver[0].points}</label>
          </div>
        )}
        {testLap != null && driver[0].laps !== null && (
          <div className="gp-detail flex-center text-center">
            <FontAwesomeIcon
              icon={
                !showDetails ? faMagnifyingGlassPlus : faMagnifyingGlassMinus
              }
              onClick={() => showDriverLaps(this)}
            />
          </div>
        )}
      </div>
      <GrandPrixLaps laps={driver[0].laps} showDetails={showDetails} />
    </div>
  );
};

export default RaceRow;
